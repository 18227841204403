
function DatatableValores(){

    this.url = null;
    this.elem = null;

    var that = this;


    var url_consulta = null;


    

    that.atualizaValores = function(url, id, valor, elem) {
        $.ajax({
            "url": url,
            "type": "POST",
            "dataType": "json",
            "data": { "id": id, "valor": valor }
        }).done(function (d) {

            if (elem != null && d.status) {
                elem.addClass("bg-success");
                console.log(d);
                return;
            } else {
                elem.addClass("bg-danger");
                return;
            }

            
        }).fail(function (e) {
                console.log("ERRO atualizaValores():");
                console.log(e);
        });
    };



    that.init = function (elem, url){
        that.elem = elem;
        that.url = url;

     

            var datatable = elem.DataTable({
                processing: true,
                fixedColumns: {
                    start: 1,
                    end: 1
                },
                fixedHeader: {
                    header: true,
                    footer: true
                },
                scrollX: true,
                scrollY: 800,

                paging: true,
                scrollCollapse: true,
                "tabIndex": 1,
                "keys": true,
                "pagingType": "full_numbers",
                "autoWidth": false,
                "lengthMenu": [[10, 25, 50, -1], [10, 25, 50, "Listar Todos"]],
                "ajax": url + "/listaValoresJSON",
                "language": {
                "url": "//cdn.datatables.net/plug-ins/9dcbecd42ad/i18n/Portuguese.json"
            },

                "columns": [
                    { "data": "idcurso" },
                    { "data": "nome",
                    "render": function(data, type, row, meta){

                        var elem = document.createElement("a");
                        elem.innerHTML = data;
                        elem.href="javascript: void(0);";
                        elem.classList.add("text-dark");
                        elem.classList.add("visualizar-dados");
                        elem.setAttribute("data-id", row["idcurso"]);
                        elem.setAttribute("data-toggle","modal");
                        elem.setAttribute("data-target", "#modalVisualizar");


            

                        return elem.outerHTML;
                    }
                },
                {
                    "data": "porcentagem_desconto",
                    "render": function(data, type, row, meta){
                        var inp = document.createElement("input");

                        inp.type = "text";
                        inp.id = "valor-" + row["idcurso"];
                        inp.setAttribute("data-id", row["idcurso"]);
                        inp.setAttribute("value", data);
                        inp.setAttribute("class", "porcentagem");



                        $("#" + inp.id).priceFormat({
                            prefix: "",
                            centsSeparator: ",",
                            thousandsSeparator: ""
                        });

                        return inp.outerHTML;
                    
                    }
                    
                },
                { "data": "tipo_nome",
                  "render": function(data, type, row, meta){

                    return "<strong>" + data + " / " + row["coligada_nome"] + "</strong>";
                  }
                },
                {
                    data: "valor_integral",
                    "render": function (data, type, row, meta) {

                        var inp = document.createElement("input");

                        inp.type = "text";
                        inp.id = "valor-" + row["idcurso"];
                        inp.setAttribute("data-id", row["idcurso"]);
                        inp.setAttribute("value", data);
                        inp.setAttribute("class", "integral");



                        $("#" + inp.id).priceFormat({
                            prefix: "",
                            centsSeparator: ",",
                            thousandsSeparator: ""
                        });

                        return inp.outerHTML;
                    }

                },
                {
                    "data": "valor_bolsa",
                    "orderable": false,
                    "render": function (data, type, row, meta) {
                        var inp = document.createElement("input");
                        inp.type = "text";
                        inp.id = "valordesconto-" + row["idcurso"];
                        inp.setAttribute("data-id", row["idcurso"]);
                        inp.setAttribute("value", data);
                        inp.setAttribute("class", "desconto");


                        $("#" + inp.id).priceFormat({
                            prefix: "",
                            centsSeparator: ",",
                            thousandsSeparator: ""
                        });
                        return inp.outerHTML;

                    }
                },
                {
                    "data": "valor_promocional",
                    "orderable": false,
                    "render": function (data, type, row, meta) {
                        var inp = document.createElement("input");
                        inp.type = "text";
                        inp.id = "valorpromocional-" + row["idcurso"];
                        inp.setAttribute("data-id", row["idcurso"]);
                        inp.setAttribute("value", data);
                        inp.setAttribute("class", "valorpromocional");

                        //var container = document.createElement("div");
                        //container.setAttribute("class", "form-group");
                        //container.appendChild(inp);


                        $("#" + inp.id).priceFormat({
                            prefix: "",
                            centsSeparator: ",",
                            thousandsSeparator: ""
                        });
                        return inp.outerHTML;

                    }

                },
                {
                    "data": null,
                    "render": function (data, type, row, meta){
                        var button = document.createElement("a");
                        button.classList.add("btn");
                        button.classList.add("btn-primary");
                        button.target = "_blank";
                        button.innerHTML="Visualizar valores de: "+row["nome"];
                        button.href= url + "/visualizar/" + row["idcurso"];
                        return button.outerHTML;
                    }
                },

                {
                    "data": "promocao_ativa",
                    "className": "checkbox-datatable",
                    "render": function (data, type, row, meta) {

                        var check = document.createElement("input");
                        check.type = "checkbox";
                        check.id = "promoativa-" + row["idcurso"];
                        check.setAttribute("data-id", row["idcurso"]);
                        check.value = data;


                        if (data == 1) {
                            check.setAttribute("checked", "");
                        } else {
                            check.removeAttribute("checked");
                        }
                        check.classList.add("promo-ativa");
                        check.classList.add("form-control");

                        var label = document.createElement("label");
                        label.innerHTML = "Promoção Ativa"; //data == 0 ? "Promoção Desativada" : "Promoção Ativada";
                        label.setAttribute("for", check.id);


                        var container = document.createElement("div");

                        container.setAttribute("class", "form-group");
                        container.appendChild(label);
                        container.appendChild(check);
                        return container.outerHTML;

                    }
                },
                {
                    "data": null,
                    "orderable": false,
                    "render": function (data, type, row, meta) {

                        var link = document.createElement("button");
                        //link.href="javascript: void(0);";
                        link.innerHTML = "Alterar Observações";
                        link.id = "observacao-" + row["idcurso"];
                        link.setAttribute("data-toggle", "modal");
                        link.setAttribute("data-target", "#modalObservacao");
                        link.setAttribute("data-id", row["idcurso"]);
                        link.setAttribute("class", "btn btn-lg btn-info btn-block modal_button");

                        /*
                        $("#"+link.id).on("click", function(e){
                            
                            var id = $(this).data("id");
                            $("#modalObservacao").modal("show", { "id": id });
    
                        });
                        */
                        return link.outerHTML;
                    }
                },
                {
                    "data": "observacao_ativa",
                    "render": function (data, type, row, meta) {

                        var check = document.createElement("input");
                        check.type = "checkbox";
                        check.id = "obsativa-" + row["idcurso"];
                        check.setAttribute("data-id", row["idcurso"]);
                        check.setAttribute("class", "obs-ativa");
                        check.value = data;
                        //check.checked = data == 0 ? false : true;
                        if (data == 1) {
                            check.setAttribute("checked", "");
                        } else {
                            check.removeAttribute("checked");
                        }
                        check.setAttribute("class", "form-control obs-ativa");

                        var label = document.createElement("label");
                        label.innerHTML = data == 0 ? "Obs. Desativado" : "Obs. Ativado";
                        label.setAttribute("for", check.id);


                        var container = document.createElement("div");

                        container.setAttribute("class", "form-group");
                        container.appendChild(label);
                        container.appendChild(check);
                        return container.outerHTML;
                    }
                }
            ]
    });


        elem.on("click", "tr .obs-ativa", function () {
            var id = $(this).data("id");
            var post_url = url + "/atualiza_status_observacao";



            var checked = $(this).is(":checked");

            var result = $.ajax({
                "url": post_url,
                "type": "POST",
                "dataType": "json",
                "data": { "id": id, "valor": checked ? 1 : 0 }
            }).done(function (d) {
  
                

            }).fail(function (e) {

            });
        });




        elem.on("focus", "tr input", function (d) {

            var cl = $(this);
            var post_url = null;
            if (cl.hasClass("integral")) {
                post_url = url + "/atualiza_investimentos";

            } else if (cl.hasClass("desconto")) {
                post_url = url + "/atualiza_investimento_desconto";

            } else if (cl.hasClass("valorpromocional")) {
                post_url = url +"/atualiza_investimento_promocional";

            }else if(cl.hasClass("porcentagem")){
                post_url = url + "/atualiza_desconto_porcentagem";
            }

            $(this).on("keyup", function(e){
                    if(e.which == 13){ // aperta ENTER
                        that.atualizaValores(post_url, $(this).data("id"), $(this).val(), $(this));
                    }

                    if(e.which == 9){ //aperta TAB
                        that.atualizaValores(post_url, $(this).data("id"), $(this).val(), $(this));
                    }
            });

            if(post_url){ 
                that.atualizaValores(post_url, $(this).data("id"), $(this).val(), $(this));
            }


        });


        elem.on("blur", "tr input", function (d) {

            var cl = $(this);
            var post_url = null;
            if (cl.hasClass("integral")) {
                post_url = url + "/atualiza_investimentos";
            } else if (cl.hasClass("desconto")) {
                post_url = url + "/atualiza_investimento_desconto";
            } else if (cl.hasClass("valorpromocional")) {
                post_url = url + "/atualiza_investimento_promocional";
            }else if(cl.hasClass("porcentagem")){
                post_url = url + "/atualiza_desconto_porcentagem";
            }



            if (post_url) that.atualizaValores(post_url, $(this).data("id"), $(this).val(), $(this));
        

        });


        elem.on("click", "tr .promo-ativa", function (e) {
            var id = $(this).data("id");
            var post =  url + "/atualiza_status_promocao";



            var checked = $(this).is(":checked");

            var result = $.ajax({
                "url": post,
                "type": "POST",
                "dataType": "json",
                "data": { "id": id, "valor": checked ? 1 : 0 }
            }).done(function (d) {

            }).fail(function (e) {

            });
        });

        $(document).on("show.bs.modal", "#modalObservacao", function (e) {

            var id = $(e.relatedTarget).data("id");
            $(this).modal("handleUpdate");

            var modal = $(this);
            var btn_envia = modal.find("#salvaObservacao");





            $.ajax({
                "url": url+"/observacao",
                "type": "POST",
                "dataType": "json",
                "data": { "id": id }
            }).done(function (d) {

                    that.update_observacao(btn_envia, id);
                    $("#campoObservacao").val("");
                    $("#campoObservacao").val(d.data.observacao);
                    $("#campoObservacao").trumbowyg({
                        "autogrow" : true,
                        "tagsToRemove" : ["script", "link", "iframe", "object", "frameset", "head", "body"]
                    });


                }).fail(function (e) {

                });


    });

$(document).on("hidden.bs.modal", "#modalObservacao", function (e) {

    $("#campoObservacao").trumbowyg("destroy");
    $("#modalObservacao").modal("handleUpdate");
    var modal = $(this);
    var btn_envia = modal.find("#salvaObservacao");
    btn_envia.off("click");
});


$(document).on("show.bs.modal", "#modalVisualizar", function(e) {

    //get data-id attribute of the clicked element
    var id = $(e.relatedTarget).data("id");
    $(this).removeData("bs.modal");
    var modalBody = $(this).find(".modal-body");

    modalBody.load(that.url_consulta + "/" + id);


});





},

    that.update_observacao = function(elem, id) {
        elem.on("click", function (e) {
            e.preventDefault();

            $.ajax({
                "url": that.url + "/altera_observacao",
                "type": "POST",
                "dataType": "json",
                "data": { "id": id, "texto": $("#campoObservacao").val() }

            }).done(function (d) {

                if (d.status) {
                    $("#modalObservacao").modal("hide");
                }
            }).fail(function (e) {

            });

            //$("frmEditaObservacao").submit();
        });
},

    that.setUrlConsulta = function(url){
        that.url_consulta = url;
    };


};